export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

//Workouts
export const WORKOUT_SUBMIT = "WORKOUT_SUBMIT";
export const FETCH_WORKOUT = "FETCH_WORKOUT";
export const SEARCH_WORKOUTS = "SEARCH_WORKOUTS";
export const START_WORKOUT = "START_WORKOUT";
export const RESET_WORKOUT = "RESET_WORKOUT";

//Classes
export const GET_ALL_CLASS_TYPES = "GET_ALL_CLASS_TYPES";
export const SET_CURRENT_CLASS = "SET_CURRENT_CLASS";
export const CLASS_SUBMIT = "CLASS_SUBMIT";
export const DELETE_CLASS = "DELETE_CLASS";
export const GET_ALL_CLASSES = "GET_ALL_CLASSES";

//Users
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_USERS = "GET_USERS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
