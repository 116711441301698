import {
    FETCH_WORKOUT,
    SEARCH_WORKOUTS,
    WORKOUT_SUBMIT,
    START_WORKOUT,
    RESET_WORKOUT
} from "../actions/types";

const initialState = {
    workouts: [],
    currentWorkout: {},
    startWorkout: false,
    message: "",
    ok: true,
    error: [],
};
export default function (state = initialState, action) {
    switch (action.type) {
        case WORKOUT_SUBMIT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentWorkout: action.payload.workout,
            };
        case SEARCH_WORKOUTS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                workouts: action.payload.workouts,
            };
        case FETCH_WORKOUT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentWorkout: action.payload.workout,
            };

        case START_WORKOUT:
            return {
                ...state,
                startWorkout: action.payload,
            };

        case RESET_WORKOUT:
            return {
                ...state,
                currentWorkout: {},
            };
        default:
            return state;
    }
}
