import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { ProtectedRoute } from "dg-admin";
import DgLazyComponent from "dg-utils/build/DgLazyComponent";
import ScrollToTop from "dg-admin/build/ScrollToTop";
import Loading from "./components/loading/loading";

// Admin Pages
const Login = lazy(() => import("./components/login/login.component"));
const PasswordReset = lazy(() => import("./components/login/passwordReset"));

//Admin - Classes
const AdminClassList = lazy(() =>
    import("./pages/admin/classes/adminClassList.component")
);

//Admin - Users
const AdminUserList = lazy(() =>
    import("./pages/admin/users/adminUserList.component")
);
const AdminCreateUser = lazy(() =>
    import("./pages/admin/users/create.component")
);
const AdminUpdateUser = lazy(() =>
    import("./pages/admin/users/update.component")
);

// User Pages
const Home = lazy(() => import("./pages/home.component"));
const CreateWorkout = lazy(() => import("./pages/createWorkout.component"));
const EditWorkout = lazy(() => import("./pages/editWorkout.component"));
const CloneWorkout = lazy(() => import("./pages/cloneWorkout.component"));
const Timer = lazy(() => import("./pages/timer.component"));
const Classes = lazy(() => import("./pages/classes.component"));

const noExternalUsers = (user) => {
    if (user?.type === "External") {
        throw "External users not allowed";
    }
};

function router() {
    return (
        <Suspense fallback={<Loading />}>
            <ScrollToTop />
            <Routes>
                <Route path="/" exact element={<Login />} />
                <Route
                    path="/home"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={Home}
                        />
                    }
                />
                <Route
                    path="/workout/create"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={CreateWorkout}
                            onLoggedIn={noExternalUsers}
                        />
                    }
                />
                <Route
                    path="/workout/edit/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={EditWorkout}
                        />
                    }
                />
                <Route
                    path="/workout/clone/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={CloneWorkout}
                            onLoggedIn={noExternalUsers}
                        />
                    }
                />
                <Route
                    path="/begin-workout/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={Timer}
                        />
                    }
                />
                <Route
                    path="/classes"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={Classes}
                        />
                    }
                />
                <Route
                    path="/admin/"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminClassList}
                            onLoggedIn={noExternalUsers}
                        />
                    }
                />
                <Route
                    path="/verifypasswordreset"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={PasswordReset}
                        />
                    }
                />
                <Route
                    path="/admin/classes"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminClassList}
                            onLoggedIn={noExternalUsers}
                        />
                    }
                />
                <Route
                    path="/admin/user"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminUserList}
                            query={{ type: { $ne: "External" } }}
                            onLoggedIn={noExternalUsers}
                        />
                    }
                />
                <Route
                    path="/admin/externaluser"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminUserList}
                            query={{ type: "External" }}
                            onLoggedIn={noExternalUsers}
                        />
                    }
                />
                <Route
                    path="/admin/user/create"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminCreateUser}
                            onLoggedIn={noExternalUsers}
                        />
                    }
                />
                <Route
                    path="/admin/user/edit/:id"
                    exact
                    element={
                        <DgLazyComponent
                            Component={ProtectedRoute}
                            component={AdminUpdateUser}
                            onLoggedIn={noExternalUsers}
                        />
                    }
                />
            </Routes>
        </Suspense>
    );
}

export default router;
