import {
    GET_ALL_CLASSES,
    CLASS_SUBMIT,
    GET_ALL_CLASS_TYPES,
    SET_CURRENT_CLASS
} from "../actions/types";

const initialState = {
    classes: [],
    classTypes: [],
    currentClass: {},
    message: "",
    ok: true,
    error: []
};
export default function (state = initialState, action) {
    switch (action.type) {
        case CLASS_SUBMIT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentClass: action.payload.class
            };
        case SET_CURRENT_CLASS:
            return {
                ...state,
                currentClass: action.payload
            };
        case GET_ALL_CLASS_TYPES:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
                classTypes: action.payload.classes
            };
        case GET_ALL_CLASSES:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
                classes: action.payload.classes
            };
        default:
            return state;
    }
}
