import React from "react";
import spinner from "./spinner.svg";
// Assets

const Loading = (props) => (
    <div
        style={{
            height: "100vh",
            flex: "1 1 100%",
            justifyContent: "center",
            alignItems: "center",
        }}
        className="container-row center"
    >
        <div className="item">{spinner}</div>
    </div>
);

export default Loading;
