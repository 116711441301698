import React, { lazy } from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";

// Library Styles
import "./styles/App.scss";
import "dg-forms/build/index.css";
import "dg-navbar/build/index.css";
import "dg-pagination/build/index.css";
import "dg-blocks/build/index.css";
import "dg-admin/build/index.css";

// Define the initial state
const preloadedState = store.getState();

function App({ assets, helmet, server, currentLocation }) {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </Provider>
    );
}

export default App;
